<style lang="less">
  .auth-user-page {
    .xsj {
      border-top: 5px solid #808695;
    }
  }
  .pink-theme {
    .auth-user-page {
      .xsj {
        border-top: 5px solid #F4628F;
      }
    }
  }
</style>

<template>
  <div class="auth-user-page">
    <base-sign-page
      title-text="创建评价与考核活动"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="showDataList"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      :loading="loading"
      table-size="large"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :need-pagination="true">
      <template v-slot:center>
        <div class="center-c">
          <div class="center-c-l">
            <search-input placeholder="标题搜索" @change="searchTitleChange" style="margin-left: 1rem;"></search-input>
          </div>
          <div class="center-c-r">
            <fm-down-select style="margin-right: 35px;" @change="onChange" :options="statusList.map(v => {return {label: v.label, value: v.key}}).concat({label: '全部状态', value: null})">
              <template>
                <div style="cursor: pointer;" class="kh-status">
                  <span>{{statusText}}</span>
                  <div class="xsj"></div>
                </div>
              </template>
            </fm-down-select>
            <fm-down-select style="margin-right: 35px;" @change="onChangeType" :options="typeList.map(v => {return {label: v.label, value: v.key}}).concat({label: '全部类型', value: null})">
              <template>
                <div style="cursor: pointer;" class="kh-status">
                  <span>{{typeText}}</span>
                  <div class="xsj"></div>
                </div>
              </template>
            </fm-down-select>
            <fm-btn @click="clickTitleMenu('add')" style="margin-right: 22px;" v-if="$authFunsProxy.add">+新增考核</fm-btn>
          </div>
        </div>
      </template>
    </base-sign-page>
    <fm-form-dialog
      :mask-closable="false"
      form-title="考核管理"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      label-align="left"
      :old-data="chooseData"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-modal
      :value="openDialogR"
      :mask-closable="false"
      width="80%"
      v-if="openDialogR"
      theme="mh-withe"
      @cancel="openDialogR = false">
      <appraisal-result :appraisalTitle="chooseData.title" :appraisal-type="chooseData.type" :is-inc="chooseData.isInc" :appraisal-id="chooseData.id"></appraisal-result>
    </fm-modal>
    <fm-modal
      :value="openDialogQr"
      :mask-closable="false"
      v-if="openDialogQr"
      title="活动二维码"
      theme="mh-withe"
      @cancel="openDialogQr = false">
      <div class="qr-dev">
        <qrcode qrWidth="200px" :qrUrl="qrUrl" v-if="qrUrl"></qrcode>
        <span>{{chooseData ? chooseData.title : ""}}</span>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'
import SearchInput from '@/components/base/SearchInput'
import Qrcode from '@/components/base/qrcode'
import Status from './cmp/status'
import Remark from './cmp/remark'
import AppraisalResult from './cmp/appraisalResult'
import OtherActions from './cmp/OtherActions'

import {
  dateOperating
} from '@/fmlib'

import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalResultDetailRequest
} from '@/api'

export default {
  components: {
    Qrcode,
    BaseSignPage,
    SearchInput,
    AppraisalResult
  },
  computed: {
    statusText () {
      return (this.statusList.find(v1 => v1.key === this.searchStatus) || {label: '状态'}).label
    },
    typeText () {
      return (this.typeList.find(v1 => v1.key === this.searchType) || {label: '类型'}).label
    },
    showDataList () {
      let data = this.dataList
      if (this.searchStatus) {
        data = data.filter(v => v.status === this.searchStatus)
      }
      if (this.searchType) {
        data = data.filter(v => v.type === this.searchType)
      }
      if (this.searchTitle) {
        data = data.filter(v => v.title.includes(this.searchTitle))
      }
      return data
    },
    tableActions () {
      return [{
        label: '二维码',
        key: 'qr'
      },
      {
        label: '结果',
        key: 'result'
      },
      {
        label: '设置',
        key: 'ready'
      },
      {
        label: '修改',
        key: 'edit'
      },
      {
        label: '删除',
        key: 'del'
      }].filter(({key}) => this.$authFunsProxy[key])
    },
    tableActionsOther: {
      get () {
        return [{
          label: '开始',
          key: 'start',
          show: (row) => {
            return row.status === 'plan'
          }
        },
        {
          label: '完成',
          key: 'over',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '取消',
          key: 'qx',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '大屏',
          key: 'screen',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '重新开始',
          key: 'restart',
          show: (row) => {
            return ['doing', 'over'].includes(row.status)
          }
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    typeList () {
      return this.$store.getters.appraisalTypeList
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '标题',
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        },
        {
          type: 'select',
          label: '类型',
          fmDisabled: this.chooseData && this.chooseData.id && true,
          key: 'type',
          selectDatas: this.typeList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '是否匿名',
          key: 'isInc',
          selectDatas: [{key: 'yes', label: '是'}, {key: 'no', label: '否'}],
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: this.statusList,
          check: {
            required: true
          }
        },
        {
          type: 'datetimePicker',
          label: '时间',
          key: 'planStartTime'
        },
        {
          type: 'input',
          label: '地点',
          key: 'address'
        }]
        return data
      }
    },
    sysRoles: {
      get () {
        return this.$store.getters.roleList
      }
    },
    qrUrl () {
      let data = null
      if (this.chooseData) {
        data = window.location.origin + '/#/wap/' + (this.chooseData.type === 'scoring' ? 'checking' : this.chooseData.type) + '?id=' + this.chooseData.id
      }
      return data
    },
    columnList: {
      get () {
        let vm = this
        return [{
          field: 'title',
          title: '标题',
          render: (h, row) => {
            return h(Remark, {
              props: {
                remark: row.title
              }
            })
          }
        },
        {
          field: 'remark',
          width: 200,
          title: '说明',
          render: (h, row) => {
            return h(Remark, {
              props: {
                remark: row.remark
              }
            })
          }
        },
        // {
        //   field: 'rule',
        //   title: '规则'
        // },
        {
          title: '状态',
          width: 90,
          field: 'status',
          align: 'left',
          render: (h, row) => {
            return h(Status, {
              props: {
                status: row.status
              }
            })
          }
        },
        {
          title: '类型',
          width: 80,
          field: 'type',
          align: 'left',
          render: (h, row) => {
            let text = (this.typeList.find(v1 => v1.key === row.type) || {label: ''}).label
            return h('div', text)
          }
        },
        {
          title: '是否匿名',
          width: 80,
          field: 'isInc',
          align: 'left',
          render: (h, row) => {
            let text = row.isInc === 'yes' ? '是' : '否'
            return h('div', text)
          }
        },
        // {
        //   title: '时间',
        //   field: 'planStartTime',
        //   align: 'left',
        //   render: (h, row) => {
        //     return h('div', row.planStartTime ? row.planStartTime.slice(0, 16) : '')
        //   }
        // },
        // {
        //   title: '地点',
        //   field: 'address',
        //   align: 'left'
        // },
        {
          title: '操作',
          slot: 'actions',
          width: 200,
          search: false,
          export: false
        },
        {
          title: '',
          field: 'otherAction',
          align: 'right',
          search: false,
          width: 150,
          render: (h, row) => {
            return h(OtherActions, {
              props: {
                row,
                chooseData: vm.chooseData
              },
              on: {
                clickAction: action => {
                  vm.chooseData = row
                  if (action) {
                    this.tableAction({data: row, action: action.key})
                  }
                }
              }
            })
          }
        }].filter(v => v.field !== 'otherAction' || this.$authFunsProxy.otherAction)
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadRData () {
      let parm = {
        appraisalId: this.chooseData.id
      }
      appraisalResultDetailRequest.get(parm)
    },
    onChange (data) {
      this.searchStatus = data
    },
    onChangeType (data) {
      this.searchType = data
    },
    searchTitleChange (data) {
      this.searchTitle = data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      } else if (data.action === 'screen') {
        this.$router.push({name: 'screen', query: {id: this.chooseData.id}})
      } else if (data.action === 'ready') {
        this.$router.push({name: 'appraisal.ready', query: {id: this.chooseData.id}})
      } else if (data.action === 'over') {
        this.over()
      } else if (data.action === 'qx') {
        this.qx()
      } else if (data.action === 'start') {
        this.start()
      } else if (data.action === 'restart') {
        this.restart()
      } else if (data.action === 'result') {
        this.openDialogR = true
      } else if (data.action === 'qr') {
        this.openDialogQr = true
      }
    },
    async restart () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定重新开始考核 ' + this.chooseData.title + ' 吗?'})
      if (result) {
        this.loading = true
        let allP = []
        // 删除考核结果明细
        let parm = {
          appraisalId: this.chooseData.id
        }
        let d1 = await appraisalResultDetailRequest.get(parm)
        let i = 0
        while (i < d1.length) {
          allP.push(appraisalResultDetailRequest.del(d1[i].id))
          i += 1
        }
        // 处理考核人
        parm = {
          appraisalId: this.chooseData.id,
          appraisalGroupType: 'becheck'
        }
        d1 = await appraisalGroupDetailRequest.get(parm)
        i = 0
        while (i < d1.length) {
          allP.push(appraisalGroupDetailRequest.update(d1[i].id, {
            status: 'wait',
            value: null,
            ranking: null,
            gotta: null
          }))
          i += 1
        }
        await Promise.all(allP)
        this.changeStatus('doing', '考核已重新开始')
      }
    },
    async start () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定开始考核 ' + this.chooseData.title + ' 吗?'})
      if (result) {
        this.changeStatus('doing')
      }
    },
    async over () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定完成考核 ' + this.chooseData.title + ' 吗?'})
      if (result) {
        this.changeStatus('over')
      }
    },
    async qx () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定取消考核 ' + this.chooseData.title + ' 吗?'})
      if (result) {
        this.changeStatus('void')
      }
    },
    async changeStatus (status, text) {
      this.loading = true
      let textMap = {
        over: '考核已完成',
        void: '考核已取消',
        doing: '考核已开始'
      }
      request.update(this.chooseData.id, {status: status}).then(() => {
        this.$notice.info({
          title: '系统提示',
          desc: text || textMap[status]
        })
        this.loading = false
        this.loadData()
      })
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除考核 ' + this.chooseData.title + ' 吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '考核已删除'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data, resolve) {
      if (data.planStartTime instanceof Date) {
        data.planStartTime = dateOperating.computeDay({days: 0, date: data.planStartTime, format: 'yy-mm-dd hh:mm:ss'})
      }
      data.carTeamIds = data.carTeamIds || []
      if (data.type === 'question' && !data.id) {
        let appraisalRule = {
          type: '选择制',
          ops: '优良中差',
          max: '100',
          min: '0',
          pre: '整数'
        }
        data.rule = JSON.stringify(appraisalRule)
      }
      if (data.type === 'scoring' && !data.id) {
        let appraisalRule = [{
          title: '',
          max: '100',
          min: '0',
          pre: '整数'
        }]
        data.rule = JSON.stringify(appraisalRule)
      }
      if (this.chooseData && this.chooseData.id) {
        request.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '考核修改完成'
          })
          this.loadData()
          resolve()
          this.openDialog = false
        })
      } else {
        request.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '考核新增成功'
          })
          this.loadData()
          resolve()
          this.openDialog = false
        })
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (parm) {
      if (parm === 're') {
        return
      }
      this.chooseData = null
      if (parm === 'add') {
        this.chooseData = {
          status: 'plan'
        }
        this.openDialog = true
      }
    },
    loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      bkhList: [],
      dataList: [],
      openDialogR: false,
      searchStatus: '',
      searchType: '',
      searchTitle: '',
      openDialogQr: false,
      noteText: '',
      loading: true,
      chooseData: null,
      openDialog: false,
      rDatas: []
    }
  }
}
</script>

<style scoped lang="less">
.kh-status {
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
    font-size: 14px;
    color: #707070;
  }
}
.qr-dev {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
  text-align: center;
  span {
    max-width: 200px;
  }
}
.xsj {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.center-c {
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .center-c-l {
    display: flex;
    align-items: center;
  }
  .center-c-r {
    display: flex;
    align-items: center;
  }
}
</style>