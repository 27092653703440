<style lang="less" scoped>
.action-t {
  position: relative;
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.action-item {
  color: #fff;
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 8px;
}
.other-actions {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  .show-all {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    margin-left: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .xsj {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: 0.5s;
  }
  .xz {
    transform:rotate(90deg);
  }
}
.pink-theme {
  .other-actions {
    .action-t {
      background-color: #F4628F;
      border: 1px solid #F4628F;
      .action-item {
        color: #FFF;
      }
    }
    .show-all {
      border: 1px solid #F4628F;
      .xsj {
        border-top: 7px solid #F4628F;
      }
    }
  }
  .actions {
    background-color: #F4628F;
  }
}
.other-actions {
  .action-t {
    background-color: #FFF;
    border: 1px solid #005AFF;
    .action-item {
      color: #005AFF;
    }
  }
  .show-all {
    border: 1px solid #005AFF;
    .xsj {
      border-top: 7px solid #005AFF;
    }
  }
}
</style>
<template>
  <div class="other-actions">
    <div class="action-t" v-if="tableActionsOther.length > 0">
      <a href="javascript:;" class="action-item" @click="showAll = false;clickAction(tableActionsOther[0])">
        {{tableActionsOther[0].label}}
      </a>
      <div class="actions" v-if="showAll" >
        <a href="javascript:;" class="action-item" :key="item.key" v-for="item in tableActionsOther" @click="showAll = false;clickAction(item);showAll = false">
          {{item.label}}
        </a>
      </div>
    </div>
    <div class="show-all" v-if="tableActionsOther.length > 0" @click="showAll = tableActionsOther.length > 1 && !showAll;clickAction()">
      <div class="xsj" :class="{'xz': showAll}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {type: Object},
    chooseData: {type: Object}
  },
  watch: {
    chooseData (value) {
      if (!value || value.id !== this.row.id) {
        this.showAll = false
      }
    }
  },
  data () {
    return {
      showAll: false
    }
  },
  computed: {
    tableActionsOther: {
      get () {
        let data = [{
          label: '开始',
          key: 'start',
          show: (row) => {
            return row.status === 'plan'
          }
        },
        {
          label: '完成',
          key: 'over',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '取消',
          key: 'qx',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '大屏',
          key: 'screen',
          show: (row) => {
            return row.status === 'doing'
          }
        },
        {
          label: '重新开始',
          key: 'restart',
          show: (row) => {
            return ['doing', 'over'].includes(row.status)
          }
        }]
        return data.filter(v => v.show(this.row))
      }
    }
  },
  methods: {
    clickAction (item) {
      this.$emit('clickAction', item)
    }
  }
}
</script>
